import React, { Fragment, useState } from 'react';

import { TablePagination, Box, Button, ButtonGroup, Typography, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton } from '@mui/material';

import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Info as InfoIcon
} from '@mui/icons-material';
import ContextMenu from '../../ContextMenu';


export default function FormBuilderFields(props) {

    // Default fields for forms
    const formFieldsPreset = [
        {
            label: 'First Name',
            type: 'first_name',
            name: 'first_name'
        },
        {
            label: 'Last Name',
            type: 'last_name',
            name: 'last_name'
        },
        {
            label: 'Email',
            type: 'email',
            name: 'email'
        },
        {
            label: 'Mobile Phone Number',
            type: 'mobile',
            name: 'mobile'
        },
        {
            label: 'Main Streaming Sevice',
            type: 'music_service',
            name: 'music_service',
            options: [
                { key: '', value: 'Please Select...'},
                { key: 'AppleMusic', value: 'Apple Music'},
                { key: 'Spotify', value: 'Spotify'},
                { key: 'iTunes', value: 'iTunes'},
                { key: 'Pandora', value: 'Pandora'},
                { key: 'youtube', value: 'YouTube'},
                { key: 'Amazon', value: 'Amazon Music'},
                { key: 'GooglePlay', value: 'Google Play'},
                { key: 'Deezer', value: 'Deezer'},
                { key: 'Audiomack', value: 'Audiomack'},
                { key: 'LinkfirePlayer', value: 'Linkfire Player'},
                { key: 'Tidal', value: 'Tidal'},
                { key: 'Soundcloud', value: 'Soundcloud'},
                { key: 'MicrosoftGroove', value: 'Microsoft Groove'},
                { key: 'napster', value: 'Napster'},
                { key: 'imusic', value: 'iMusic'},
                { key: 'kkbox', value: 'KKBOX'},
                { key: 'joox', value: 'Joox'},
                { key: 'none', value: 'I don\'t use one'},
                { key: 'other', value: 'Other'}
            ]
        },
        {
            label: 'Country',
            type: 'country',
            name: 'country'
        },
        {
            label: 'City',
            type: 'city',
            name: 'city'
        },
        {
            label: 'Zip/Postal Code',
            type: 'postalcode',
            name: 'postalcode'
        },
        {
            label: 'Gender',
            type: 'gender',
            name: 'gender',
            options: [
                {key: '', value: ''},
                {key: 'male', value: 'Male'},
                {key: 'female', value: 'Female'},
                {key: 'other', value: 'Prefer not to disclose'}
            ]
        },
        {
            label: 'Day & Month of Birth',
            type: 'daymonth_of_birth',
            name: 'dob'
        },
        {
            label: 'Custom Question',
            type: 'text',
            name: 'text',
            placeholder: true
        },
        {
            label: 'Custom Checkbox',
            type: 'checkbox',
            name: 'checkbox',
            placeholder: true
        },
        {
            label: 'Custom Date',
            type: 'date',
            name: 'date',
            placeholder: true
        },
        {
            label: 'Custom Drop-Down',
            type: 'select',
            name: 'select',
            options: [
                { key: 'answer1', value: 'Answer 1' },
                { key: 'answer2', value: 'Answer 2' }
            ],
            placeholder: true
        },
        {
            label: 'Custom Checkbox (Multiple Choices)',
            type: 'checkbox_multiple',
            name: 'question',
            options: [
                { key: 'answer1', value: 'Answer 1' },
                { key: 'answer2', value: 'Answer 2' }
            ],
            placeholder: true
        }
    ];

    var formFieldsDefault = [
        formFieldsPreset[0],
        formFieldsPreset[1],
        formFieldsPreset[2]
    ]

    var value = props.value;

    if( value && !Array.isArray(value) )
    {
        var newValue = [];

        Object.keys(value).forEach((formFieldDataIndex) => {
            var newSingleValue = value[formFieldDataIndex]
            newSingleValue.name = formFieldDataIndex;

            if( newSingleValue.options && !Array.isArray(newSingleValue.options) )
            {
                var newOptions = [];

                Object.keys(newSingleValue.options).forEach((optionIndex) => {
                    var singleOption = newSingleValue.options[optionIndex];
                    
                    newOptions.push({
                        key: optionIndex,
                        value: singleOption
                    });
                });
                
                newSingleValue.options = newOptions;
            }

            newValue.push( value[formFieldDataIndex] );
        });

        value = newValue;
    }

    if( !value )
    {
        value = formFieldsDefault;
    }

    const [formFieldFocus, setFormFieldFocus] = useState(null);
    const [formFieldData, setFormFieldData] = useState(value);

    const handleFormFieldData = (formFieldData) => {
        setFormFieldData(formFieldData);

        if( props.onChange )
        {
            props.onChange(formFieldData);
        }
    };

    const handleFieldFocus = (fieldIndex) => (e) => {
        if( formFieldData[fieldIndex] && !formFieldData[fieldIndex].name )
        {
            setFormFieldFocus(fieldIndex);
        }
    };

    const handleFieldBlur = (fieldIndex) => (e) => {
        if( formFieldFocus && formFieldData[fieldIndex] && ( formFieldData[fieldIndex]['label'] ) )
        {
            var formFieldDataNew = [...formFieldData];

            formFieldDataNew[fieldIndex]['name'] = formFieldData[fieldIndex]['label'].toLowerCase().replaceAll(' ', '_');
    
            handleFormFieldData(formFieldDataNew);
            setFormFieldFocus(null);
        }
    };

    const handleFieldDelete = (fieldIndex) => {
        var newValue = [...formFieldData];

        newValue.splice(fieldIndex, 1);

        handleFormFieldData(newValue);
    };

    const handleFieldAdd = (fieldIndex, position) => {
        var newValue = [...formFieldData];

        newValue.splice(position == 'below' ? fieldIndex + 1 : fieldIndex, 0, {
            
        });

        handleFormFieldData(newValue);
    };

    const handleChangeField = (formFieldDataIndex, field) => (e) => {
        var formFieldDataNew = [...formFieldData];

        var value = e.target.value;

        if( field === 'type' )
        {
            var newValues = formFieldsPreset.find(element => ( element.name == value || element.type == value ) );
            
            if( !newValues.placeholder )
            {
                formFieldDataNew[formFieldDataIndex]['name'] = newValues.name || null;
            }

            formFieldDataNew[formFieldDataIndex]['label'] = newValues.label || null;
            formFieldDataNew[formFieldDataIndex]['options'] = newValues.options || null;
        }

        if( e.target.type === 'checkbox' )
        {
            value = e.target.checked;
        }

        formFieldDataNew[formFieldDataIndex][field] = value;

        handleFormFieldData(formFieldDataNew);
    };

    const handleChangeOptionField = (formFieldDataIndex, optionIndex, field) => (e) => {
        var formFieldDataNew = [...formFieldData];

        formFieldDataNew[formFieldDataIndex]['options'][optionIndex][field] = e.target.value;

        handleFormFieldData(formFieldDataNew);
    };

    const handleAddOptionField = (formFieldDataIndex, optionInd, position) => {
        var formFieldDataNew = [...formFieldData];

        formFieldDataNew[formFieldDataIndex]['options'].splice(position == 'below' ? optionInd + 1 : optionInd, 0, {
            
        });

        handleFormFieldData(formFieldDataNew);
    };

    const handleDeleteOptionField = (formFieldDataIndex, optionIndex) => {
        var formFieldDataNew = [...formFieldData];

        formFieldDataNew[formFieldDataIndex]['options'].splice(optionIndex, 1);

        handleFormFieldData(formFieldDataNew);
    };

    return (
    <>
        <Grid container>
            <Grid item xs={3}>
                <InputLabel shrink>Field Name <Tooltip title={<>This is the name of the field in ExactTarget. You should only change this value if you know what you're doing.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
            </Grid>
            <Grid item xs={3} pl={2}>
                <InputLabel shrink>Field Type <Tooltip title={<>This is the type of field. Some fields come with pre-set properties.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
            </Grid>
            <Grid item xs={5} pl={2}>
                <InputLabel shrink>Field Label <Tooltip title={<>This is text the user will see when looking at the form, prompting them to enter their information.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
            </Grid>
            <Grid item xs={1} pl={2}>
                <InputLabel shrink>Req. <Tooltip title={<>Is the user required to enter this field?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
            </Grid>
        {
            formFieldData &&
                formFieldData.map((formFieldDataSingle, formFieldDataIndex) => {

                    if( formFieldDataSingle['field_type'] && !formFieldDataSingle['type'] )
                    {
                        formFieldDataSingle['type'] = formFieldDataSingle['field_type'];
                    }

                    if( formFieldDataSingle['type'] === 'music_service' )
                    {
                        formFieldDataSingle['options'] = formFieldDataSingle['options'] || formFieldsPreset.find(formFieldPreset => ( formFieldPreset.type === formFieldDataSingle['type'] ) ||  ( formFieldPreset.name === formFieldDataSingle['type']) ).options;
                    }

                    return (
                        <Fragment key={formFieldDataIndex}>
                            <Grid item xs={3} pb={2}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={formFieldDataSingle['name'] || ''}
                                    onChange={handleChangeField(formFieldDataIndex, 'name')}
                                />
                            </Grid>

                            <Grid item xs={3} pb={2} pl={2}>
                                <Select
                                    size="small"
                                    value={formFieldDataSingle['type'] || ''}
                                    fullWidth
                                    displayEmpty={true}
                                    onChange={handleChangeField(formFieldDataIndex, 'type')}
                                >
                                    {
                                        ( formFieldDataSingle['type']  !== '' ) &&
                                            <MenuItem value="">None</MenuItem>
                                    }

                                    {formFieldsPreset.map((formFieldsPresetSingle, formFieldsPresetIndex) => {
                                        return (
                                            <MenuItem key={formFieldsPresetIndex} value={formFieldsPresetSingle.type || formFieldsPresetSingle.name}>{formFieldsPresetSingle.label}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>
                            <Grid item xs={5} pb={2} pl={2}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={formFieldDataSingle['label'] || ''}
                                    onChange={handleChangeField(formFieldDataIndex, 'label')}
                                    onFocus={handleFieldFocus(formFieldDataIndex)}
                                    onBlur={handleFieldBlur(formFieldDataIndex)}
                                />
                                
                                { ( formFieldDataSingle['options'] && formFieldDataSingle['options'].length > 0 ) &&
                                    <Grid container>
                                    {formFieldDataSingle['options'].map((optionSingle, optionIndex) => {

                                        return (
                                            <Fragment key={optionIndex}>
                                                <Grid pt={1} pr={1} item xs={5.5}>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        value={optionSingle.key || ''}
                                                        onChange={handleChangeOptionField(formFieldDataIndex, optionIndex, 'key')}
                                                    />
                                                </Grid>
                                                <Grid pt={1} item xs={5.5}>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        value={optionSingle.value || ''}
                                                        onChange={handleChangeOptionField(formFieldDataIndex, optionIndex, 'value')}
                                                    />
                                                </Grid>
                                                <Grid pt={1} item xs={1}>
                                                    <ContextMenu
                                                        options={[
                                                            {label: 'Add Field Above', onClick: () => { handleAddOptionField(formFieldDataIndex, optionIndex, 'above'); } },
                                                            {label: 'Add Field Below', onClick: () => { handleAddOptionField(formFieldDataIndex, optionIndex, 'below'); } },
                                                            {label: 'Delete', onClick: () => { handleDeleteOptionField(formFieldDataIndex, optionIndex); } }
                                                        ]}
                                                    />
                                                </Grid>
                                            </Fragment>
                                        )
                                    })}
                                    </Grid>
                                }
                            </Grid>

                            <Grid item xs={0.5} pb={2} pl={2}>
                                <Checkbox onChange={handleChangeField(formFieldDataIndex, 'mandatory')} checked={formFieldDataSingle['mandatory'] ? true : false} />
                            </Grid>

                            <Grid item xs={0.5} pb={2} pl={2}>
                                <ContextMenu
                                    options={[
                                        {label: 'Add Field Above', onClick: () => { handleFieldAdd(formFieldDataIndex, 'above'); }},
                                        {label: 'Add Field Below', onClick: () => { handleFieldAdd(formFieldDataIndex, 'below'); }},
                                        {label: 'Delete', onClick: () => { handleFieldDelete(formFieldDataIndex); }}
                                    ]}
                                />
                            </Grid>
                        </Fragment>
                    )
                })
        }
        </Grid>

    </>
  );
}