import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserRepository, UserAPI, AcquisitionRepository, AcquisitionAPI } from '@warnerconnect/library';

import { TablePagination, Box, InputLabel, Tooltip, InputAdornment, Select, TextField, MenuItem, Button, Typography, CircularProgress, Link as MLink, Checkbox, ListItemText, OutlinedInput, Autocomplete, FormControlLabel, Switch } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';
import { formReducer } from '../../../Context/formReducer';
import { useSnackbar } from "notistack";

import {
    Info as InfoIcon,
    AddBox as AddBoxIcon,
    Save as SaveIcon,
    ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

import TeamList from '../../Elements/TeamList';
import TeamUserList from '../../Elements/TeamUserList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import UserSearch from '../../Elements/Forms/Fields/UserSearch';
import Fieldset from '../../Elements/Forms/Fieldset';
import ContentBox from '../../Elements/ContentBox';
import ModalDialogue from '../../Elements/ModalDialogue';
import PageContainer from './../../Elements/PageContainer';
import CountrySelector from './../../Elements/Forms/Fields/CountrySelector';
import RecordLabelSelector from './../../Elements/Forms/Fields/RecordLabelSelector';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import Loader from '../../Elements/Loader';
import TabGroup from '../../Elements/TabGroup';

import TeamsHeroImage from '../../../assets/media/hero/campaigns.jpg';
import { LoadingButton } from '@mui/lab';

export default function TeamsIndex() {
    useTitle('Teams');

    let query = useQuery();
    const history = useHistory();
    const location = useLocation();

    const { enqueueSnackbar } = useSnackbar();

    const userDetails = useAuthState();

    if( !query.get('page') )
    {
        query.set('page', 0)
    }

    if( !query.get('perPage') )
    {
        query.set('perPage', 25)
    }
    
    const [teamEditStep, setTeamEditStep] = useState(0);

    const [countryData, setCountryData] = useState();
    const [countryDataLoading, setCountryDataLoading] = useState(true);
    const [recordLabelData, setRecordLabelData] = useState();
    const [recordLabelDataLoading, setRecordLabelLoading] = useState(true);

    const [teamData, setTeamData] = useState();
    const [teamDataLoading, setTeamDataLoading] = useState(false);
    const [teamDataEvents, setTeamDataEvents] = useState(0);

    const userRepository = new UserRepository();
    userRepository.setBearerToken(userDetails.accessToken);

    const acquisitionRepository = new AcquisitionRepository();
    acquisitionRepository.setBearerToken(userDetails.accessToken);

    const userAPI = new UserAPI();
    userAPI.setBearerToken(userDetails.accessToken);

    const [teamSearchModal, setTeamSearchModal] = useState(false);
    const [teamSearchLabelData, setTeamSearchLabelData] = useState();
    const [teamSearchLabelDataLoading, setTeamSearchLabelDataLoading] = useState(false);

    const [teamEditUserSearchData, setTeamEditUserSearchData] = useState({});

    const [teamEditModal, setTeamEditModal] = useState(false);
    const [teamEditUsers, setTeamEditUsers] = useState();
    const [teamEditLoading, setTeamEditLoading] = useState(false);
    const [teamEditSubmitting, setTeamEditSubmitting] = useState(false);

    const [teamCreateModal, setTeamCreateModal] = useState(false);
    const [teamCreateSubmitting, setTeamCreateSubmitting] = useState(false);

    const [teamCreateData, setTeamCreateData] = useReducer(formReducer, {});

    const [teamEditData, setTeamEditData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
    );

    const [teamSearchData, setTeamSearchData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
    );

    const refreshTeamData = event => {
        setTeamDataEvents(teamDataEvents + 1);
    }

    const toggleTeamSearchModal = event => {
        setTeamSearchModal(!teamSearchModal);
    }

    const toggleTeamCreateModal = event => {
        setTeamCreateData({ type: 'reset' });
        setTeamCreateModal(!teamCreateModal);
    }

    const closeTeamEditModal = event => {
        history.push('/teams');
    }
    
    const setTeamCreateDataSingle = (name, value) => {
        setTeamCreateData({ name: name, value: value});
    };

    const setTeamEditDataSingle = (name, value) => {
        setTeamEditData({ [name]: value });
    };

    const setTeamSearchDataSingle = (name, value) => {
        setTeamSearchData({ [name]: value });
    };

    const handleTeamCreateChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setTeamCreateDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setTeamEditDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleSearchChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setTeamSearchDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    // Detect path change to hand page edit
    useEffect(() => {
        let locationParts = location.pathname.split('/');

        setTeamEditLoading(false);
        setTeamEditModal(false);
        setTeamEditData({});
        setTeamEditUsers({});

        if( locationParts[2] && locationParts[3] && ( locationParts[3] === 'edit' ) )
        {
            setTeamEditLoading(true);
            setTeamEditModal(true);

            let teamId = parseInt(locationParts[2]);

            userRepository.getTeam(teamId, {include: ['countries', 'users']}).then( team => {
                setTeamEditData({
                    id: team.getId(),
                    name: team.getName(),
                    global: team.getGlobal(),
                    countries: team.getCountries()
                });

                setTeamEditUsers(team.getUsers());

                setTeamEditLoading(false);
            });
        }
        else
        {

        }

    }, [location]);

    useEffect(() => {
        acquisitionRepository.getCountries({limit: 200})
            .then( countries => {
                var countryData = [];

                countries.getItems().forEach(country => {
                    countryData.push(country);
                });

                setCountryData(countryData);
                setCountryDataLoading(false);
            });

        acquisitionRepository.getRecordLabels({limit: 200})
            .then( recordLabels => {
                var recordLabelOptions = [];

                recordLabels.getItems().forEach(recordLabel => {
                    recordLabelOptions.push({
                        label: recordLabel.getName(),
                        id: recordLabel.getId()
                    });

                    if( recordLabel.getChildRecordLabels() )
                    {
                        recordLabel.getChildRecordLabels().forEach(childRecordLabel => {
                            recordLabelOptions.push({
                                label: ' - '+childRecordLabel.getName(),
                                id: childRecordLabel.getId()
                            });
                        });
                    }
                });

                setRecordLabelData(recordLabelOptions);
                setRecordLabelLoading(false);
            });

    }, []);

    useEffect(() => {
        setTeamDataLoading(true);

        let limit = query.get('perPage');

        let offset = query.get('page') !== 0 ? ( query.get('page') * query.get('perPage') ) : 0;

        let criteria = teamSearchData || {};

        let options = {
            limit: limit,
            offset: offset,
            include: ['childTeams', 'countries', 'regions', 'users']
        };

        userRepository.getTeams(options, criteria).then( response => {
            setTeamData(response);
            setTeamDataLoading(false);
        });
    }, [userDetails.team, query.get('page'), query.get('perPage'), teamDataEvents, teamSearchData]);

    useEffect(() => {

    }, []);

    function handleChangePage(event, newValue){
        query.set('page', newValue);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeRowsPerPage(event, newValue){
        query.set('perPage', newValue.props.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function labelDisplayedRows( { from, to, count, page } ){
        return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`;
    }

    return (
        <>
            <ModalDialogue open={teamEditModal} size="large" onClose={closeTeamEditModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Edit Team</Typography>                    
                </Box>
                { teamEditLoading ?
                    <Loader size="x-small" />
                    :
                    <TabGroup
                        tabList={[
                            {
                                label: 'Settings',
                                content: <>
                                    <Fieldset>
                                        <FieldContainer xs={12}>
                                            <InputLabel shrink>Name</InputLabel>
                                            <TextField
                                                name="name"
                                                fullWidth
                                                value={teamEditData['name'] || ''}
                                                onChange={handleChange}
                                            />
                                        </FieldContainer>

                                        <FieldContainer xs={12}>
                                            <InputLabel shrink>Territories</InputLabel>
                                            {
                                                countryData &&
                                                    <CountrySelector
                                                        name="countries"
                                                        value={teamEditData['countries'] || ''}
                                                        options={countryData}
                                                        onChange={(event, newValues) => {
                                                            setTeamEditDataSingle('countries', newValues);
                                                        }}
                                                    />
                                            }
                                        </FieldContainer>

                                        <FieldContainer xs={12}>
                                            <InputLabel shrink>Global <Tooltip title={<>Does this team need to have visibility on everything globally, for instance, should this team be able to see an Ed Sheeran campaign so they can share the link?</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="global"
                                                        onChange={handleChange}
                                                        checked={teamEditData['global'] ? true : false}
                                                    />
                                                }
                                            />
                                        </FieldContainer>

                                        <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                                            <LoadingButton
                                                sx={{mb: 2}}
                                                type="submit"
                                                loading={teamEditSubmitting}
                                                onClick={() => {
                                                    setTeamEditSubmitting(true);

                                                    userRepository
                                                        .updateTeam(teamEditData['id'], {
                                                            name: teamEditData['name'],
                                                            global: teamEditData['global']
                                                        })
                                                        .then(updatedTeam => {
                                                            var countryIds = [];

                                                            if( teamEditData['countries'] )
                                                            {
                                                                teamEditData['countries'].forEach(country => {
                                                                    countryIds.push(country.getId());
                                                                });
                                                            }

                                                            return userRepository.putTeamCountries(updatedTeam.getId(), countryIds)
                                                        })
                                                        .then(updatedTeam => {
                                                            setTeamEditSubmitting(false);

                                                            enqueueSnackbar("Team "+updatedTeam.getName()+" updated", {
                                                                variant: 'success'
                                                            });
                    
                                                            refreshTeamData();
                                                        });
                                                }}
                                                loadingPosition="end"
                                                variant="contained"
                                                endIcon={<SaveIcon />}
                                            >
                                                Save Changes
                                            </LoadingButton>
                                        </FieldContainer>
                                    </Fieldset>
                                </>
                            },
                            {
                                label: 'Users',
                                content: <>
                                <Fieldset>

                                    <FieldContainer xs={12}>
                                        <UserSearch
                                            name="keywords"                
                                            fullWidth
                                            placeholder="Add new user"
                                            onChange={(event, newValue) => {
                                                //var newValues = [];
                                                userRepository.addTeamUsers(teamEditData['id'], [newValue.id], {include: ['users']}).then( team => {
                                                    if( team.getUsers() )
                                                    {
                                                        setTeamEditUsers(team.getUsers());
                                                    }

                                                    enqueueSnackbar(newValue.label+" added to "+teamEditData['name'], {
                                                        variant: 'success'
                                                    });
                                                });
                                            }}
                                        />
                                    </FieldContainer>
                                </Fieldset>

                                <TeamUserList
                                    noRecordsLabel="This team doesn't have any users"
                                    filter={toggleTeamSearchModal}
                                    data={teamEditUsers}
                                    onDelete={userId => {
                                        return userRepository.deleteTeamUsers(teamEditData['id'], [userId], {include: ['users']})
                                            .then(deleteTeamResponse => {
                                                enqueueSnackbar("User removed from "+teamEditData['name'], {
                                                    variant: 'success'
                                                });

                                                setTeamEditUsers(deleteTeamResponse.getUsers());

                                                return deleteTeamResponse;
                                            });
                                    }}
                                />

                                </>
                            }
                        ]}
                    />
                    
                }
            </ModalDialogue>

            <ModalDialogue open={teamCreateModal} size="large" onClose={toggleTeamCreateModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Create Team{teamCreateData['parentTeam'] && ` Under ${teamCreateData['parentTeam'].getName()}`}</Typography>                    
                </Box>
                <form autoComplete="off">
                <Fieldset>
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Name</InputLabel>
                        <TextField
                            name="name"
                            fullWidth
                            value={teamCreateData['name'] || ''}
                            onChange={handleTeamCreateChange}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Territories</InputLabel>
                        {
                            countryData &&
                                <CountrySelector
                                    name="countries"
                                    value={teamCreateData['countries'] || ''}
                                    options={countryData}
                                    onChange={(event, newValues) => {
                                        setTeamCreateDataSingle('countries', newValues);
                                    }}
                                />
                        }
                    </FieldContainer>

                    <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                        <LoadingButton
                            sx={{mb: 2}}
                            type="submit"
                            loading={teamCreateSubmitting}
                            loadingPosition="end"
                            variant="contained"
                            endIcon={<AddBoxIcon />}
                            onClick={() => {
                                setTeamCreateSubmitting(true);

                                userRepository
                                    .createTeam({
                                        name: teamCreateData['name'],
                                        parentTeam: teamCreateData['parentTeam'] ? teamCreateData['parentTeam'].getId() : null,
                                        global: teamEditData['global']
                                    })
                                    .then(newTeam => {
                                        if( teamCreateData['countries'] )
                                        {
                                            var countryIds = [];

                                            teamCreateData['countries'].forEach(country => {
                                                countryIds.push(country.getId());
                                            });

                                            return userRepository.putTeamCountries(newTeam.getId(), countryIds)
                                        }

                                        return newTeam;
                                    })
                                    .then(newTeam => {
                                        setTeamCreateSubmitting(false);
                                        setTeamCreateModal(false);

                                        enqueueSnackbar("Team "+newTeam.getName()+" created", {
                                            variant: 'success'
                                        });

                                        refreshTeamData();

                                        history.push('/teams/'+newTeam.getId()+'/edit');
                                    });
                            }}
                        >
                            Create Team
                        </LoadingButton>
                    </FieldContainer>
                </Fieldset>
                </form>
            </ModalDialogue>

            <ModalDialogue open={teamSearchModal} size="large" onClose={toggleTeamSearchModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Search</Typography>                    
                </Box>

                <Fieldset>
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Keywords</InputLabel>
                        <TextField
                            name="keywords"
                            fullWidth
                            value={teamSearchData['keywords'] || ''}
                            onChange={handleSearchChange}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Territories</InputLabel>
                        {
                            ( countryData && teamData ) &&
                                <CountrySelector
                                    name="country"
                                    value={teamSearchData['country'] || ''}
                                    options={countryData}
                                    onChange={(event, newValues) => {
                                        var newA2Values = [];

                                        newValues.forEach(valueSingle => {
                                            newA2Values.push(valueSingle.getA2());
                                        });
                                        
                                        setTeamSearchDataSingle('country', newA2Values);
                                    }}
                                />
                        }
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink htmlFor="label">Team Label <Tooltip title={<>For internal use only. Please select the correct label for this team. This information is used for data collection and, aside from admins, only members of this record label will be able to make changes to and view stats for this team. If you can\'t find the label you\'re looking for then please email <MLink to="mailto:matt.lowden@warnermusic.com">matt.lowden@warnermusic.com</MLink>.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>

                        {
                            recordLabelData &&
                                <RecordLabelSelector
                                    name="recordLabel"
                                    value={teamSearchData['recordLabel'] || []}
                                    options={recordLabelData}
                                    onChange={(event, newValue) => {
                                        var newValues = [];
        
                                        newValue.forEach(label => {
                                            newValues.push(label.id);
                                        });
        
                                        setTeamSearchDataSingle('recordLabel', newValues);
                                    }}
                                />
                        }
                    </FieldContainer>
                </Fieldset>
            </ModalDialogue>

            <Hero image={TeamsHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Welcome</Typography>
                    <Typography paragraph>Here you can edit existing teams and create new teams.</Typography>
                    <Typography variant="button"><MLink component={Link} to="/resources">Learn More</MLink></Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton onClick={toggleTeamCreateModal} color="secondary">Create Team<AddCircleOutlineIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>
            <PageContainer>
                <Typography variant="h4">Teams</Typography>
                { teamDataLoading && <Loader /> }
                { !teamDataLoading && teamData &&
                <>
                    <TeamList
                        filter={toggleTeamSearchModal}
                        data={teamData.getItems()}
                        onDelete={teamId => {
                            return userRepository.deleteTeam(teamId)
                                .then(deleteTeamResponse => {
                                    enqueueSnackbar("Team deleted", {
                                        variant: 'success'
                                    });

                                    refreshTeamData();

                                    return deleteTeamResponse;
                                });
                        }}
                        onClickAddChild={parentTeam => {
                            toggleTeamCreateModal();
                            setTeamCreateDataSingle('parentTeam', parentTeam);
                        }}
                    />
                    {
                        ( teamData.getTotal() > 0 ) &&
                            <TablePagination
                                component="div"
                                count={teamData.getTotal()}
                                page={parseInt(query.get('page'))}
                                rowsPerPage={parseInt(query.get('perPage'))}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={labelDisplayedRows}
                            />
                    }
                </>
                }
            </PageContainer>
        </>
    );
};
